<template>
  <div id="home">
    <!-- 首页顶部轮播图 start -->
    <div class="home-header">
      <!-- <div class="part-blur"
                style="width: 100%; height: 200px; position: absolute; top: 35px; background: black; filter: opacity(.1); z-index: 3;">
            </div> -->

      <div class="carousel-warp">
        <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
        <!--                <el-carousel height="36vw" v-if="homeShow.length">-->
        <el-carousel height="38vw">
          <el-carousel-item v-for="(item, index) in homeShow" :key="index">
            <el-image style="width: 100%; height: 100%" :src="item" fit="cover">
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="news-rules-warp">
      <!-- 新闻 start -->
      <div class="news-warp msg-warp">
        <div class="msg-warp-title module-title">
          <span class="title-textE">
            <p>SECURITY</p>
            <p style="font-weight: bold; font-style: italic">NEWS</p>
          </span>
          <p class="title-textC">安全新闻</p>
          <router-link to="/news"
            ><span class="explore">Explore more >></span></router-link
          >
        </div>

        <div class="news">
          <!-- 头条新闻 -->
          <div class="top-new card" @click="toDetail('安全新闻', 0)">
            <el-image :src="topNew.img" fit="cover"> </el-image>
            <div class="top-new-text card-text">
              <p>{{ topNew.title }}</p>
              <p>
                <span class="explore">Explore more >></span>
                <span class="date">{{ topNew.date }}</span>
              </p>
            </div>
          </div>
          <!-- 其他新闻 -->
          <div class="other-news msg-ul">
            <ul>
              <li
                v-for="(item, index) in news"
                :key="index"
                @click="toDetail('安全新闻', index + 1)"
              >
                <div class="date">
                  <p style="font-size: 30px">{{ item.date[2] }}</p>
                  <p style="font-size: 16px">
                    {{ item.date[0] }}.{{ item.date[1] }}
                  </p>
                </div>
                <div class="li-text">
                  <div class="title">{{ item.title }}</div>
                  <div class="content">{{ item.summary }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 法规 start -->
      <div class="rules-warp msg-warp">
        <div class="msg-warp-title module-title">
          <span class="title-textE">
            <p>POLICY</p>
            <p style="font-weight: bold; font-style: italic">STATUTE</p>
          </span>
          <p class="title-textC">政策法规</p>
          <router-link to="/rules"
            ><span class="explore">Explore more >></span></router-link
          >
        </div>

        <div class="rules msg-ul">
          <ul>
            <li
              v-for="(item, index) in rules"
              :key="index"
              @click="toDetail('政策法规', index)"
            >
              <div class="date">
                <p style="font-size: 30px">{{ item.date[2] }}</p>
                <p style="font-size: 16px">
                  {{ item.date[0] }}.{{ item.date[1] }}
                </p>
              </div>
              <div class="li-text">
                <div class="title">{{ item.title }}</div>
                <div class="content">{{ item.summary }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="theme-activities-warp">
      <div class="act act1">
        <img src="../../assets/act1.jpg" fit="cover" />
      </div>
      <div class="act act2">
        <img src="../../assets/act2.jpg" fit="cover" />
      </div>
    </div>

    <div class="school-warp">
      <div class="school-warp-title module-title">
        <div>
          <span class="title-textE">
            <p>SCHOOL</p>
            <p style="font-weight: bold; font-style: italic">
              ELEGANT DEMEANOR
            </p>
          </span>
          <p class="title-textC">学校风采</p>
        </div>

        <router-link to="/schools"
          ><span class="explore">Explore more >></span></router-link
        >
      </div>
      <div class="school-warp-content">
        <div class="school-ul" ref="schoolsWarp">
          <ul id="schools" ref="schools">
            <li class="card" v-for="(item, index) in schools" :key="index">
              <el-image :src="item.img" fit="cover"></el-image>
              <div class="card-text">
                <p>{{ item.name }}</p>
                <p><span class="explore">Explore more >></span></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="expert-warp">
      <div class="expert-warp-title module-title">
        <div>
          <span class="title-textE">
            <p>SAFETY</p>
            <p style="font-weight: bold; font-style: italic">SPECIALIST</p>
          </span>
          <p class="title-textC">安全专家</p>
        </div>
      </div>
      <div class="expert-warp-content">
        <!--                <span class="explore">Explore more >></span>-->
      </div>
      <div class="expert-ul" ref="expertsWarp">
        <ul id="experts" ref="experts">
          <li
            class="card"
            v-for="(item, index) in experts"
            :key="index"
            @click="toDetail('安全专家', index)"
          >
            <el-image :src="item.img" fit="cover">
              <div
                slot="error"
                style="
                  height: 100%;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  opacity: 0.5;
                  background: #cccccc;
                "
              >
                <i class="el-icon-s-custom"></i>
                <p>暂无照片，点击查看介绍</p>
              </div>
            </el-image>
            <div class="expert-text">
              <span class="expert-name">{{ item.name }}</span>
              <span class="explore">Explore more >></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import login from '@/components/login';
// import QRCode from 'qrcodejs2';

export default {
  name: "home",
  components: {},
  created() {
    this.getNews();
    this.getRules();
    this.getSchools();
    this.getExperts();
  },
  mounted() {
    // let schools = this.$refs['schoolsWarp'].height;
    // let schoolsScroll = this.$refs['schools'].height;
    // let schoolsHide = schools - schoolsScroll;
  },
  data() {
    return {
      // homeShow: [
      //     "https://z3.ax1x.com/2021/05/11/gUrrbF.jpg",
      // ],
      homeShow: [
        require("../../assets/homeShow1.png"),
        require("../../assets/homeShow2.png"),
      ],
      news: [],
      topNew: {},
      rules: [],
      schools: [],
      experts: [],
      // qrcodeText: "hdd", // 微信扫码关注 二维码内容
    };
  },
  methods: {
    // makeQRcode() {
    // 	let qrcode = new QRCode(document.getElementById("qrcode"), {
    // 		width : 200,
    // 		height : 200
    // 	});
    // 	qrcode.makeCode(this.qrcodeText)
    // }
    getNews() {
      let data = [
        {
          title:
            "我司首席安全顾问罗宏森教授受邀参加高新区应急体系建设“十四五” 规划专题研讨暨专家评审会",
          img: require("../../assets/news/topNew1.png"),
          date: "2021/06/02",
          summary:
            "6月1日上午，成都高新区召开应急体系建设“十四五”规划专题研讨暨专家评审会。会议由党工委委员、管委会副主任陈卫东主持，邀请了四川师范大学公共安全与应急研究院院长罗宏森教授、西南交通大学马剑教授、中国工程物理研究院材料物理研究所张敏高级政工师，成都市应急管理局、高新区应急局、高新区发展规划局、高新消防救援大队、相关负责人参加了会议。",
        },
        {
          title: "教育部颁布《未成年人学校保护规定》",
          date: "2021/06/01",
          summary:
            "为贯彻落实新修订的未成年人保护法，在广泛调研、公开征求意见基础上，教育部部长陈宝生于6月1日签发第50号教育部令，颁布",
          publisher: "心安理得安全科技研究院",
        },
        {
          title:
            "关于征求《关于加强学校安全风险防控体系 建设的意见（第二次征求意见稿）》 意见建议的通知",
          date: "2021/05/31",
          summary:
            "2021 年 5 月 22 日，市委常委会第 180 次会议审议了《关于加强学校安全风险防控体系建设的意见（送审稿）》（以下简称",
          publisher: "成都市教育局",
        },
        {
          title: "教育部办公厅关于做好预防中小学生溺水事故工作的通知",
          date: "2021/05/21",
          summary:
            "随着各地气温迅速上升，天气日渐炎热，汛期即将来临，学生溺水事故进入多发高发期。日前，湖北、湖南、广西等地先后发生多起溺水事件，造成多名中小学生死亡，令人痛心。为做好预防学生溺水事故有关工作，保障广大中小学生生命安全，现将有关事项通知如下",
          publisher: "心安理得安全科技研究院",
        },
      ];

      this.topNew = data.shift();

      // 除头条新闻外其他新闻需分离日期年月日
      data.forEach((item) => {
        item.date = item.date.split(/-|[.]|[/]/);
        // console.log(item.date)
        // return date;
      });
      this.news = data;
    },
    getRules() {
      let data = [
        {
          title: "《义务教育学校管理标准》",
          date: "2021/05/01",
          summary:
            "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安",
        },
        {
          title: "《中小学幼儿园安全管理办法》",
          date: "2021/05/01",
          summary:
            "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安",
        },
        {
          title: "习近平：积极推进我国应急管理体系和能力现代...",
          date: "2021/05/01",
          summary:
            "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安",
        },
        // {
        //                 "title": "台州2岁男童误食磁力珠导致肠道破损8处，被送往...",
        //                 "date": "2021/05/01",
        //                 "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
        //             },
      ];

      // 政策法规需分离日期年月日
      data.forEach((item) => {
        item.date = item.date.split(/-|[.]|[/]/);
        // console.log(item.date)
        // return date;
      });
      this.rules = data;
    },
    getSchools() {
      // 获取学校列表
      let data = [
        {
          name: "(金苹果)花园城幼儿园、天府新区",
          img: require("../../assets/schoolpics/(金苹果)花园城幼儿园、天府新区.jpg"),
        },
        {
          name: "四川天府七中",
          img: require("../../assets/schoolpics/四川天府七中.jpg"),
        },
        {
          name: "四川天府新区（成南幼儿园）",
          img: require("../../assets/schoolpics/四川天府新区（成南幼儿园）.jpg"),
        },
        {
          name: "四川天府新区大林小学（玉皇博爱校区）",
          img: require("../../assets/schoolpics/四川天府新区大林小学（玉皇博爱校区）.jpg"),
        },
        {
          name: "四川天府新区大林幼儿园",
          img: require("../../assets/schoolpics/四川天府新区大林幼儿园.jpg"),
        },
        {
          name: "四川天府新区第六小学",
          img: require("../../assets/schoolpics/四川天府新区第六小学.jpg"),
        },
        {
          name: "四川天府新区第五幼儿园",
          img: require("../../assets/schoolpics/四川天府新区第五幼儿园.jpg"),
        },
        {
          name: "四川天府新区籍田小学",
          img: require("../../assets/schoolpics/四川天府新区籍田小学.jpg"),
        },
        {
          name: "四川天府新区煎茶小学",
          img: require("../../assets/schoolpics/四川天府新区煎茶小学.jpg"),
        },
        {
          name: "四川天府新区煎茶幼儿园",
          img: require("../../assets/schoolpics/四川天府新区煎茶幼儿园.jpg"),
        },
        {
          name: "四川天府新区麓湖小学",
          img: require("../../assets/schoolpics/四川天府新区麓湖小学.jpg"),
        },
        {
          name: "四川天府新区三星中学",
          img: require("../../assets/schoolpics/四川天府新区三星中学.jpg"),
        },
        {
          name: "四川天府新区万安幼儿园",
          img: require("../../assets/schoolpics/四川天府新区万安幼儿园.jpg"),
        },
        {
          name: "四川天府新区新兴小学",
          img: require("../../assets/schoolpics/四川天府新区新兴小学.jpg"),
        },
        {
          name: "四川天府新区幸福麓山幼儿园",
          img: require("../../assets/schoolpics/四川天府新区幸福麓山幼儿园.jpg"),
        },
        {
          name: "四川天府新区学恩幼儿园",
          img: require("../../assets/schoolpics/四川天府新区学恩幼儿园.jpg"),
        },
        {
          name: "四川天府新区永兴幼儿园",
          img: require("../../assets/schoolpics/四川天府新区永兴幼儿园.jpg"),
        },
        {
          name: "四川天府新区正兴中学",
          img: require("../../assets/schoolpics/四川天府新区正兴中学.jpg"),
        },
        {
          name: "天府五小",
          img: require("../../assets/schoolpics/天府五小.jpg"),
        }
      ];

      this.schools = data;
    },
    getExperts() {
      let data = [
        {
          name: "罗宏森",
          img: require("../../assets/experts/luohongsen.png"),
        },
        {
          name: "余云湖",
          img: require("../../assets/experts/yuyunhu.jpg"),
        },
        {
          name: "吴松涛",
          img: require("../../assets/experts/wusongtao.png"),
        },
        {
          name: "王凯",
          img: require("../../assets/experts/wangkai.jpg"),
        },
        {
          name: "陈谦",
          img: require("../../assets/experts/expert.png"),
        },
      ];

      this.experts = data;
    },
    // toNews() {  // 先将整个新闻模块绑定跳转
    //     this.$router.push({path: '/news'})
    // },
    toDetail(title, index) {
      console.log(title, index);
      this.$router.push({
        path: "/detail",
        query: { title: title, id: index },
      });
    },
  },
};
</script>

<style scoped lang="less">
// 公共属性 start

.router-link-active {
  text-decoration: none !important;
  color: #fff !important;
}

a {
  text-decoration: none !important;
  color: #fff !important;
}

p > span {
  color: rgb(32, 149, 131);
  font-weight: 600;
  // border-bottom: 2px solid rgb(32,149,131);
}

.explore {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-style: italic;
  color: white;
  cursor: pointer;
}

.module-title {
  color: white;

  .title-textE {
    font-size: 22px;
    font-family: Century Gothic;
    line-height: 1;

    p {
      margin: 0;
    }
  }

  .title-textC {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}

.card {
  width: 412px;
  // height: 478px;
  // min-width: 90vw;
  background: #e9e9e9;
  float: left;
  /*margin-top: 22px;*/
  cursor: pointer;

  .el-image {
    width: 100%;
    height: 330px;
    // min-width: 90vw;
  }

  .card-text {
    // min-width: 90vw;
    height: 150px;
    padding: 20px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    .explore {
      font-size: 12px;
      color: #999999;
    }
  }
}

// 公共属性 end

.home-header {
  width: 100%;

  .carousel-warp {
    width: 100%;

    .el-carousel__item {
      // -webkit-filter: blur(3px);
      // filter: blur(3px);
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 40vw;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
}

.news-rules-warp {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: white;

  // news/rules公共样式 start
  .msg-warp {
    padding: 5vw;

    .explore {
      font-size: 16px;
    }

    .msg-warp-title {
      width: 100%;
      margin-bottom: 40px;

      .explore {
        float: right;
        margin-top: -60px;
      }
    }

    .msg-ul {
      li {
        width: 100%;
        // height: 80px;
        padding: 22px 0 22px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .date {
          width: 80px;
          height: 80px;
          padding: 15px 0 15px 0;
          background: rgba(255, 255, 255, 0.12);
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          line-height: 1;
          margin-right: 16px;

          p {
            margin: 0;
            // padding: 0;
            font-family: DINOT;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .li-text {
          width: ~"calc(100% - 100px)";
          overflow: hidden;

          .title {
            font-size: 20px;
            line-height: 2;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .content {
            font-size: 14px;
            opacity: 0.4;
            height: 40px;
          }
        }
      }
    }
  }

  // news/rules公共样式 end

  .news-warp {
    width: 65%;
    // margin-right: 10px;
    // min-width: 770px;
    background: linear-gradient(90deg, #07a597, #44b587);

    .top-new {
      margin-top: 22px;

      .top-new-text {
        .date {
          font-size: 16px;
          color: #7fa99d;
          float: right;
        }
      }
    }

    // news top-new样式 end

    .other-news {
      width: ~"calc(100% - 456px)";
      float: right;
    }
  }

  .rules-warp {
    width: 35%;
    // height: 330px;
    background: linear-gradient(90deg, #07a597, #2daf8d);
  }
}

// 新闻法规 end

.theme-activities-warp {
  width: 100%;
  padding: 25px 40px 17px 40px;
  background: url("../../assets/actbg@3x.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .act {
    width: ~"calc(50% - 19px)";
    height: 100%;

    img {
      margin: 0;
      padding: 19px;
      /*width: 878px;*/
      /*height: 346px;*/
      width: 100%;
    }
  }
}

// 学校风采 start
.school-warp {
  width: 100%;
  /*margin-bottom: 30px;*/
  display: flex;
  flex-wrap: wrap;
  /*width: 100%;*/
  height: 578px;
  /*margin-top: 5px;*/

  .school-warp-title {
    width: 35%;
    height: 100%;
    padding: 5vw;
    // margin-right: 10px;
    // min-width: 770px;
    background: linear-gradient(90deg, #fda033, #ff7326);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .explore {
      color: white;
    }
  }

  .school-warp-content {
    width: 65%;
    height: 100%;
    background: #c7c7c7;

    .school-ul {
      width: 100%;
      height: 378px;
      overflow: hidden;
      position: relative;
      margin-top: 100px;
      margin-left: -100px;
      /*border: 4px solid red;*/

      .card {
        width: 350px;

        .el-image {
          height: 280px;
        }

        .card-text {
          height: 100px;
        }
      }

      ul {
        list-style: none;
        left: 0;
        top: 0;
        /*width: 100%;*/
        display: flex;
        flex-shrink: 0;
        position: absolute;
        z-index: 9;
        // background-color: red;
        animation: scoll 15s infinite;
        -webkit-animation: scoll 15s ease-in-out infinite alternate running
          forwards;

        .keyframes {
          from {
            left: 27px;
          }

          to {
            left: ~"calc(100% - 5 * 339px)"; // 学校个数 5
          }
        }

        @keyframes scoll {
          .keyframes;
        }

        @-webkit-keyframes scoll {
          .keyframes;
        }

        li {
          margin-right: 27px;
        }
      }

      ul:hover {
        /*注意点: 动画添加给谁就让谁暂停*/
        animation-play-state: paused;
      }

      ul:hover li {
        opacity: 0.3;
      }

      ul li:hover {
        opacity: 1;
      }
    }
  }
}

// 学校风采 end

// 安全专家 start
.expert-warp {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 768px;
  overflow: hidden;

  .expert-warp-title {
    width: 65%;
    background: #2c2d2d;
    height: 100%;
    padding: 5vw;
  }

  .expert-warp-content {
    width: 35%;
    background: linear-gradient(90deg, #07a597, #44b587);
    overflow: hidden;

    .explore {
      position: absolute;
      right: 100px;
      top: 110px;
      cursor: pointer;
    }
  }

  .expert-ul {
    position: absolute;
    width: 100%;
    padding: 5vw;
    padding-top: 260px;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .card {
        width: 250px;
        margin-bottom: 15vw;

        .el-image {
          height: 320px;
        }

        .expert-text {
          height: 72px;
          padding: 20px;
          align-items: center;

          .expert-name {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .explore {
            font-size: 12px;
            color: #999999;
            float: right;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .part-blur {
    display: none;
  }

  .msg-warp {
    width: 100vw !important;

    .msg-warp-title {
      margin-bottom: 10vw;
    }
  }

  .news-warp {
    .msg-warp-title {
      margin: 0 !important;
    }

    .top-new {
      width: 90vw !important;

      .el-image {
        height: 70vw !important;
      }

      .top-new-text {
        height: 35vw !important;
        padding-bottom: 1vw !important;
      }
    }

    .other-news {
      width: 100% !important;
    }
  }

  .theme-activities-warp {
    /*display: none;*/
    margin: 0;
    padding: 5vw;

    .act {
      width: 100%;
      margin: 0;

      img {
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }

    .act:first-child img {
      margin-bottom: 2vw;
    }
  }

  .school-warp {
    height: auto;

    .school-warp-title {
      width: 100%;
    }

    .school-warp-content {
      width: 100%;

      .school-ul {
        margin: 5vw 0 5vw 0;
      }
    }
  }

  .expert-warp {
    height: auto !important;

    .expert-warp-title {
      width: 100%;
      height: 100% !important;
    }

    .expert-warp-content {
      .explore {
        position: absolute;
        right: 30px;
        top: 100px;
        cursor: pointer;
      }
    }

    .expert-ul {
      position: static;
      padding: 3vw 0 3vw 0 !important;
      background: linear-gradient(90deg, #07a597, #44b587);

      .card {
        margin: 3vw !important;
      }
    }
  }
}
</style>
